<script setup>
import { DialogWrapper } from 'vue3-promise-dialog';
import { useCartStore } from '~/stores/cart';

const route = useRoute();

const cartStore = useCartStore();
const isMobile = ref(false);

onMounted(() => {
    if (process.client) {
        isMobile.value = window.innerWidth < 760;
    }
});

</script>
<template>
    <div>
        <div class="main-background" :class="{ 'with-image': !['/checkout'].includes(route.path) }"></div>
        <div style="min-height: 100vh; display: flex; flex-direction: column;">
            <div class="container-lg">
                <Sidebar />
                <CartSidebar />
            </div>
            <HeaderNavbar />
            <div>
                <slot />
            </div>
            <Footer style="margin-top: auto"
                :style="{ paddingBottom: cartStore.count > 0 && isMobile ? '120px' : (isMobile ? '40px' : '0px') }" />
            <ClientOnly>
                <DialogWrapper />
            </ClientOnly>
        </div>
    </div>
</template>